import React from 'react';

import Masthead from '../components/masthead';
import Content from '../components/building-solutions/content';
import Footer from '../components/footer-cta';

const HomeTemplate = ({ copy }) => {
	const { masthead, content, footer } = copy;
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} />
			<Content data={content} />
			<Footer text={footer.text} cta={footer.cta} img={footer.img} />
		</>
	);
};

export default HomeTemplate;
