import React from 'react';
import styled from 'styled-components';

import mdToHtml from '../../util/mdToHtml';

import topBgImg from '../../../static/img/ConcreteBG_light_top.jpg';
import bottomBgImg from '../../../static/img/ConcreteBG_light_bottom.jpg';
import whiteHex from '../../../static/img/white-hex.svg';

const StyledSection = styled.section`
	background-color: ${(p) => p.theme.color.snowflake};
	background-image: url(${whiteHex}), url(${whiteHex}), url(${topBgImg}),
		url(${bottomBgImg});
	background-size: 35%, 35%, 100% auto, 100% auto;
	background-position: -2rem 1.5rem, calc(100% + 2rem) 95%, center top,
		center bottom;
	background-repeat: no-repeat;

	@media (max-width: 768px) {
		background-position: center 5rem, center 95%, center top, center bottom;
		background-size: contain, contain, contain, contain;
	}

	h3 {
		font-weight: 800;
		margin-bottom: 0.5em;
	}

	.text-container {
		p {
			margin-bottom: 1em;
		}

		ul {
			margin-bottom: 1.5em;
		}
	}

	.content-container {
		padding: 3.25rem 0;

		.row-container {
			margin-bottom: 3.125rem;
		}

		.image-container {
			max-width: 500px;

			@media (max-width: 768px) {
				margin: 0 auto;
			}
		}
	}
`;

const Content = ({ data }) => {
	const { intro, services } = data;

	return (
		<StyledSection className="section">
			<div className="container">
				<div className="content-container">
					<h2>{intro.title}</h2>
					<div className="row-container">
						<div className="columns">
							<div className="column">
								<p>{intro.text}</p>
							</div>
						</div>
					</div>
					<div className="row-container">
						<div className="columns">
							{intro.details.map((detail) => (
								<div className="column" key={detail.title}>
									<div className="details-container">
										<h3>{detail.title}</h3>
										<p>{detail.text}</p>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="image-container">
								<img src={services.image} alt={services.imageAlt} />
							</div>
						</div>
						<div className="column">
							<h2>{services.title}</h2>
							<div
								className="text-container"
								dangerouslySetInnerHTML={{ __html: mdToHtml(services.text) }}
							/>
						</div>
					</div>
				</div>
			</div>
		</StyledSection>
	);
};

export default Content;
