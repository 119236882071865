import React from 'react';

import Layout from '../components/layout';
import BuildingSolutionsTemplate from '../templates/building-solutions-template';
import SEO from '../components/seo';

const copy = {
	description:
		'EK Construction Co. offers turn-key solutions and full service commercial and residential contracting in Simcoe, Muskoka, York and the GTA Regions.',
	masthead: {
		title: 'Building Solutions',
		img: '/img/bulding_solutions-masthead.jpg',
	},
	content: {
		intro: {
			title: 'Project Delivery',
			text:
				'There are many different methods of Project Delivery; however, there are essentially only two that are relevant to the typical contract size, and that are standard to the industry and sectors that we operate in. The two primary methods of Project Delivery used by the EK Construction Company are:',
			details: [
				{
					title: 'Traditional',
					text:
						'In this example, the project owner would contract and complete design separately, and then receive proposals for the execution of the design only. This is the most traditional method of contracting construction services.',
				},
				{
					title: 'Design-Build',
					text:
						'A method of project delivery in which the contractor is responsible for delivery of the project from start to finish. That is, the contractor is responsible for all planning, design, execution, and completion of the project.',
				},
			],
		},
		services: {
			title: 'Services',
			text:
				'The EK Construction Company offers the following services: \n\n* **General contracting services** \n* **Construction management services**\n* **Trade services**\n* **Commercial tenant fit-out services less than 5,000 sf**\n* **Residential building and construction services**\n\n<i>* Consulting, design, pre- and post-construction, building maintenance, and contract administration services are all available on a case-by-case basis.</i>',
			image: '/img/ean-plans.jpg',
			imageAlt: 'Ean Koo',
		},
	},
	footer: {
		text: 'Let’s discuss your next commercial or residential project.',
		cta: {
			text: 'CONTACT US',
			link: '/contact/',
		},
		img: '/img/EK_Home_Bottom.jpg',
	},
};

const BuildingSolutionsPage = () => {
	return (
		<Layout>
			<SEO title="Home" description={copy.description} />
			<BuildingSolutionsTemplate copy={copy} />
		</Layout>
	);
};

export default BuildingSolutionsPage;
